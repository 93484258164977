import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useDispatch, useSelector } from "react-redux";
import { CreateValidation } from "./validation";
import useWindowSize from "../../../../hooks/useWindowSize";
import { UpdateServiceAction } from "../../../../redux/services.reducer";
import { InputTextarea } from "primereact/inputtextarea";

const init_create = {
  title: "",
  description: "",
};

function UpdateDialog(props) {
  const { open, handleClose, title = "Updating", value } = props;
  const payload = useSelector((state) => state.ServiceReducer.payload);
  // item values :  firstName, lastName, email, phoneNumber, country, password
  const [item, setItem] = useState({ ...init_create });
  const dispatch = useDispatch();
  const size = useWindowSize();

  useEffect(() => {
    setItem({ ...value });
  }, [value]);

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "500px";
      case "lg":
        return "500px";
      case "md":
        return "500px";
      case "sm":
        return "500px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = () => {
    if (CreateValidation(item)) {
      dispatch(UpdateServiceAction(item, handleClose));
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize() }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-12 md:col-12">
          <label>Service Title*</label>
          <InputText
            name="title"
            value={item.title}
            onChange={handleChange}
            required
            autoFocus
          />
        </div>
        <div className="field col-12 md:col-12">
          <label>Service Description*</label>
          <InputTextarea
            rows={5}
            name="description"
            value={item.description}
            onChange={handleChange}
            required
          />
        </div>
      </div>
    </Dialog>
  );
}

export default UpdateDialog;
