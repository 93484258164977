import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

import { useSelector, useDispatch } from "react-redux";

import Pagination from "../../../MyComponents/Pagination/Pagination";

import DeleteDialog from "./popups/DeleteDialog";
import AddDialgog from "./popups/AddDialgog";
import UpdateDialog from "./popups/UpdateDialog";

import FilterComp from "./Filter";

import usePopup from "../../../hooks/usePopup";
import { GetAdminsAction } from "../../../redux/admins.reducer";
import ImgBack from "../../../MyComponents/Image/ImgBack";
import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";

const init_filter = {
  // email:"",
  pays: "",
  suspended: "",
  verified: "",
};

const ActionBodyTemplate = ({ row }) => {
  const [openU, handleOpenU, handleCloseU] = usePopup(row);
  const [openD, handleOpenD, handleCloseD] = usePopup(row);

  return (
    <div
      className="actions"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {/* ################## UPDATE ################## */}
      <Button
        icon="pi pi-pencil"
        tooltip={`Update Admin ${row.fullname}`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenU()}
      />

      {openU && (
        <UpdateDialog
          open={openU}
          handleClose={handleCloseU}
          value={row}
          title={`Update ${row.fullname}`}
        />
      )}

      {/* ################## DELETE ################## */}

      {row.type !== "SUPERADMIN" && (
        <Button
          icon="pi pi-trash"
          tooltip={`Delete Admin ${row.title}`}
          tooltipOptions={{ position: "bottom" }}
          className="p-button-rounded p-button-text p-button-danger mr-0"
          onClick={() => handleOpenD()}
        />
      )}
      {openD && (
        <DeleteDialog
          open={openD}
          handleClose={handleCloseD}
          value={row}
          title={`Delete Admin ${row.title}`}
        />
      )}
    </div>
  );
};

const GestAdmins = () => {
  const admins = useSelector((state) => state.AdminReducer.admins);
  const user = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;
  const [openAdd, handleOpenAdd, handleCloseAdd] = usePopup("add");
  const [Filter, setFilter] = useState({ ...init_filter });
  //-------------------------------- Dialog States --------------------------------------------

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetAdminsAction(Filter));
  };

  useEffect(() => {
    reloadData();
  }, [Filter]);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Admins</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            tooltip="Reload Data"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />
          {user.type === "SUPERADMIN" && (
            <Button
              label="New"
              icon="pi pi-plus"
              tooltip="Add Admin"
              tooltipOptions={{ position: "bottom" }}
              className="p-button-primary mr-2"
              onClick={handleOpenAdd}
            />
          )}
          {openAdd && (
            <AddDialgog
              open={openAdd}
              handleClose={handleCloseAdd}
              title="Add New Admin"
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const AdminAvat = (row) => {
    let words = row?.fullname?.split(" ");

    return (
      <AvatarComponent
        src={null}
        circle={false}
        name={words[0]}
        lastname={words[1]}
      />
    );
  };

  const AdminPhone = (row) => {
    return row?.phoneNumber;
  };

  const AdminName = (row) => {
    return `${row?.fullname}`;
  };

  const AdminEmail = (row) => {
    return row?.email;
  };

  const AdminType = (row) => {
    return row?.type;
  };

  const Actions = (row) => {
    return user.type === "SUPERADMIN" ? (
      <ActionBodyTemplate row={row} />
    ) : (
      "Not Allowed"
    );
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

          <DataTable
            value={[...admins]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No Admins found."
            header={
              <FilterComp
                filter_name="Filter Admins"
                init_filter={init_filter}
                setFilter={setFilter}
              />
            }
            responsiveLayout="scroll"
          >
            <Column field="Admin" header="Admin" body={AdminAvat} />
            <Column field="Admin Name" header="Admin Name" body={AdminName} />
            <Column
              field="Admin Email"
              header="Admin Email"
              body={AdminEmail}
            />
            <Column header="Admin Phone" body={AdminPhone} />
            <Column header="Admin Role" body={AdminType} />
            <Column header="Actions" body={Actions} />
          </DataTable>
          <Pagination max={max} onPageChange={setPage} />
        </div>
      </div>
    </div>
  );
};

export default GestAdmins;
