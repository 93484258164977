import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useDispatch, useSelector } from "react-redux";
import { CreateValidation } from "./validation";
import useWindowSize from "../../../../hooks/useWindowSize";
import { UpdateNutriServicesAction } from "../../../../redux/nutritionists.reducer.js";
import { MultiSelect } from "primereact/multiselect";
import useAprocheNuts from "../../../../hooks/useData/useAprocheNuts.js";

const init_create = {
  ServicesNut: [],
};

function UpdateServiceNuts(props) {
  const { open, handleClose, title = "Update Popup", value } = props;
  const payload = useSelector((state) => state.NutriReducer.payload);
  const [item, setItem] = useState({ ...init_create });
  const dispatch = useDispatch();
  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "500px";
      case "lg":
        return "500px";
      case "md":
        return "500px";
      case "sm":
        return "500px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  useEffect(() => {
    setItem(value);
  }, [value]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = () => {
    if (CreateValidation(item)) {
      dispatch(UpdateNutriServicesAction(item, handleClose));
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize(), maxHeight: "90vh" }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-12 md:col-12">
          <label>Select Services</label>
          <MultiServiceselect
            name="ServicesNut"
            value={item.ServicesNut}
            onChange={handleChange}
          />
        </div>
      </div>
    </Dialog>
  );
}

export const MultiServiceselect = ({
  onChange = () => {},
  name = "",
  value = [],
}) => {
  const services = useAprocheNuts();

  const itemTemplate = (option) => {
    if (option) {
      return <span>{option.title}</span>;
    }
    return "Select Services";
  };

  const SelectedItemTemplate = (option) => {
    if (option) {
      return (
        <span
          style={{
            backgroundColor: "#262438",
            color: "white",
            padding: "2px 6px",
            margin: "0px 5px 0px 0px",
            borderRadius: "5px",
          }}
        >
          {option.title}
        </span>
      );
    }
    return "Select Services";
  };

  return (
    <MultiSelect
      placeholder="Select Services"
      optionLabel="email"
      className="w-100"
      maxSelectedLabels={5}
      style={{ width: "100%" }}
      value={value}
      options={services}
      onChange={(e) => onChange({ target: { name, value: e.value } })}
      itemTemplate={itemTemplate}
      selectedItemTemplate={SelectedItemTemplate}
      filter
    />
  );
};

export default UpdateServiceNuts;
