import { toast } from "react-hot-toast";

export const CreateValidation = (item) => {
  if (item?.title?.length === 0) {
    toast.error("Title is required");
    return false;
  }

  if (item?.description?.length === 0) {
    toast.error("Description required");
    return false;
  }

  return true;
};
