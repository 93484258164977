import { toast } from "react-hot-toast";
import axios from "../custom/axios";

// ################################# KEYS #################################

const keys = {
  update_aprochenuts: "update_aprochenuts",
  payload: "payloade_aprochenut",
  all: "all_aprochenut",
};

// ################################# INIT #################################

export const InitialState = {
  payload: false,
  data: [],
};

// ################################# REDUCERS #################################

export const AprochenutReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.update_aprochenuts:
      return { ...state, data: action.value };
    case keys.payload:
      return { ...state, payload: action.value };
    default:
      return state;
  }
};

// ################################# ACTIONS #################################

const AddAprochenutAction = (aprochenut, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.post(`/api/approche-nutritionnelle/create`, {
        ...aprochenut,
      });

      dispatch(GetAprochenutsAction());

      toast.success("Aprochenut added successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const GetAprochenutsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.get(`/api/approche-nutritionnelle/get-all`);

      console.log(response?.data);

      // every thing is ok here
      dispatch({
        type: keys.all,
        value: {
          payload: false,
          data: response?.data?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateAprochenutAction = (aprochenut, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(
        `/api/approche-nutritionnelle/update/${aprochenut._id}`,
        {
          ...aprochenut,
        }
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetAprochenutsAction());
      toast.success("Aprochenut updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const DeleteAprochenutAction = (aprochenut, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.delete(
        `/api/approche-nutritionnelle/delete/${aprochenut._id}`
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetAprochenutsAction());
      toast.success("Aprochenut deleted successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  AddAprochenutAction,
  GetAprochenutsAction,
  DeleteAprochenutAction,
  UpdateAprochenutAction,
};
