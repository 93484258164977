import React, { useRef } from "react";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";

function Notifications() {
  const menu2 = useRef(null);
  return (
    <div className="card">
      <div className="flex align-items-center justify-content-between mb-4">
        <h5>Notifications</h5>
        <div>
          <Button
            type="button"
            icon="pi pi-ellipsis-v"
            className="p-button-rounded p-button-text p-button-plain"
            onClick={(event) => menu2.current.toggle(event)}
          />
          <Menu
            ref={menu2}
            popup
            model={[
              { label: "Add New", icon: "pi pi-fw pi-plus" },
              { label: "Remove", icon: "pi pi-fw pi-minus" },
            ]}
          />
        </div>
      </div>

      <span className="block text-600 font-medium mb-3">TODAY</span>
      <ul className="p-0 mx-0 mt-0 mb-4 list-none">
        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
          <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
            <i className="pi pi-dollar text-xl text-blue-500" />
          </div>
          <span className="text-900 line-height-3">
            Richard Jones
            <span className="text-700">
              {" "}
              has purchased a blue t-shirt for{" "}
              <span className="text-blue-500">79$</span>
            </span>
          </span>
        </li>
        <li className="flex align-items-center py-2">
          <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
            <i className="pi pi-download text-xl text-orange-500" />
          </div>
          <span className="text-700 line-height-3">
            Your request for withdrawal of{" "}
            <span className="text-blue-500 font-medium">2500$</span> has been
            initiated.
          </span>
        </li>
      </ul>

      <span className="block text-600 font-medium mb-3">YESTERDAY</span>
      <ul className="p-0 m-0 list-none">
        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
          <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
            <i className="pi pi-dollar text-xl text-blue-500" />
          </div>
          <span className="text-900 line-height-3">
            Keyser Wick
            <span className="text-700">
              {" "}
              has purchased a black jacket for{" "}
              <span className="text-blue-500">59$</span>
            </span>
          </span>
        </li>
        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
          <div className="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
            <i className="pi pi-question text-xl text-pink-500" />
          </div>
          <span className="text-900 line-height-3">
            Jane Davis
            <span className="text-700">
              {" "}
              has posted a new questions about your product.
            </span>
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Notifications;
