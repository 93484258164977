import { toast } from "react-hot-toast";

export const CreateValidation = (item) => {
  if (item?.title?.length === 0) {
    toast.error("Title is required");
    return false;
  }

  if (item?.description?.length === 0) {
    toast.error("Description required");
    return false;
  }

  if (item?.image?.length === 0) {
    toast.error("Main image required");
    return false;
  }

  if (item?.tags?.length === 0) {
    toast.error("Tags required");
    return false;
  }

  if (item?.content?.length === 0) {
    toast.error("Content required");
    return false;
  }

  return true;
};
