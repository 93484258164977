import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from "redux";

import thunk from "redux-thunk";

import { UserReducer } from "../user.reducer";
import { SportReducer } from "../sports.reducer";
import { CertifReducer } from "../certifs.reducer";
import { BlogReducer } from "../blogs.reducer";
import { AdminReducer } from "../admins.reducer";
import { ServiceReducer } from "../services.reducer";
import { CoacheReducer } from "../coaches.reducer";
import { NutriReducer } from "../nutritionists.reducer";
import { UsersReducer } from "../users.reducer";
import { StudentReducer } from "../students/student.reducer";
import { SousAdminsReducer } from "../sousadmins/sousadmins.reducer";
import { feedbacksReducer } from "../feedbacks/feedbacks.reducer";
import { ConvReducer } from "../conversation/conversation.reducer";
import { AprochenutReducer } from "../aprocheNut.reducer";
import { ConsultmodeReducer } from "../ConsultMode.reducer";
import { NutserviceReducer } from "../NutrService.reducer";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const allReducers = combineReducers({
  UserReducer,
  UsersReducer,
  SportReducer,
  CoacheReducer,
  NutriReducer,
  ServiceReducer,
  AdminReducer,
  BlogReducer,
  StudentReducer,
  CertifReducer,
  SousAdminsReducer,
  feedbacksReducer,
  ConvReducer,
  AprochenutReducer,
  ConsultmodeReducer,
  NutserviceReducer,
});

const store = createStore(allReducers, composeEnhancer(applyMiddleware(thunk)));

export { store };
