import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAprochenutsAction } from "../../redux/aprocheNut.reducer";

function useAproches() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.AprochenutReducer.data);

  useEffect(() => {
    dispatch(GetAprochenutsAction());
  }, []);

  return data;
}

export default useAproches;
