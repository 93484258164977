export const DateParser = (date_str) => {
  if (date_str) {
    const date = new Date(date_str);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}/${month}/${day}`;
  } else {
    return "not mentioned";
  }
};

export const makeDate2 = (str) => {
  let date = new Date(str);
  let year = date.getFullYear();
  let monthNumb = date.getMonth() + 1;
  let month = monthNumb < 9 ? "0" + monthNumb : monthNumb;
  let day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
  return `${year}-${month}-${day}`;
};
