import { toast } from "react-hot-toast";
import axios from "../custom/axios";

// ################################# KEYS #################################

const keys = {
  update_blogs: "update_blogs",
  payload: "payloade_blog",
  all: "all_blog",
};

// ################################# INIT #################################

export const InitialState = {
  payload: false,
  blogs: [],
};

// ################################# REDUCERS #################################

export const BlogReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.update_blogs:
      return { ...state, blog: action.value };
    case keys.payload:
      return { ...state, payload: action.value };
    default:
      return state;
  }
};

// ################################# ACTIONS #################################

const AddBlogAction = (blog, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.post(`/api/blogs/create`, {
        ...blog,
      });

      console.log(response?.data);

      dispatch(GetBlogsAction());
      toast.success("Blog added successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const GetBlogsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.get(`/api/blogs/get-all`);

      console.log(response?.data);

      // every thing is ok here
      dispatch({
        type: keys.all,
        value: {
          payload: false,
          blogs: response?.data?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateBlogAction = (blog, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(`/api/blogs/update/${blog._id}`, {
        ...blog,
      });

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetBlogsAction());
      toast.success("Blog updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const DeleteBlogAction = (blog, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.delete(`/api/blogs/delete/${blog._id}`);

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetBlogsAction());
      toast.success("Blog deleted successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

export { AddBlogAction, GetBlogsAction, DeleteBlogAction, UpdateBlogAction };
