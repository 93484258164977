import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

import { useSelector, useDispatch } from "react-redux";

import Pagination from "../../../MyComponents/Pagination/Pagination";

import DeleteDialog from "./popups/DeleteDialog";

import FilterComp from "./Filter";

import usePopup from "../../../hooks/usePopup";
import { GetUsersAction } from "../../../redux/users.reducer";
import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";

const init_filter = {
  // email:"",
  pays: "",
  suspended: "",
  verified: "",
};

const ActionBodyTemplate = ({ row }) => {
  const [openD, handleOpenD, handleCloseD] = usePopup(row);

  return (
    <div
      className="actions"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {/* ################## DELETE ################## */}

      <Button
        icon="pi pi-trash"
        tooltip={`Delete User ${row.title}`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-danger mr-0"
        onClick={() => handleOpenD()}
      />

      {openD && (
        <DeleteDialog
          open={openD}
          handleClose={handleCloseD}
          value={row}
          title={`Delete User ${row.title}`}
        />
      )}
    </div>
  );
};

const GestUsers = () => {
  const users = useSelector((state) => state.UsersReducer.users);
  const dispatch = useDispatch();

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;
  const [Filter, setFilter] = useState({ ...init_filter });
  //-------------------------------- Dialog States --------------------------------------------

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetUsersAction(Filter));
  };

  useEffect(() => {
    reloadData();
  }, [Filter]);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Users</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            tooltip="Reload Data"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />
        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const UserAvat = (row) => {
    let words = row?.fullname?.split(" ");

    return (
      <AvatarComponent
        src={null}
        circle={false}
        name={words[0]}
        lastname={words[1]}
      />
    );
  };

  const UserName = (row) => {
    return `${row?.fullname}`;
  };

  const Email = (row) => {
    return row?.email;
  };

  const Phone = (row) => {
    return row?.phoneNumber;
  };

  const Actions = (row) => {
    return <ActionBodyTemplate row={row} />;
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

          <DataTable
            value={[...users]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No Users found."
            header={
              <FilterComp
                filter_name="Filter Users"
                init_filter={init_filter}
                setFilter={setFilter}
              />
            }
            responsiveLayout="scroll"
          >
            <Column header="User" body={UserAvat} />
            <Column field="User Name" header="User Name" body={UserName} />
            <Column field="User Email" header="User Email" body={Email} />
            <Column field="User Phone" header="User Phone" body={Phone} />
            <Column header="Actions" body={Actions} />
          </DataTable>
          <Pagination max={max} onPageChange={setPage} />
        </div>
      </div>
    </div>
  );
};

export default GestUsers;
