import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useDispatch, useSelector } from "react-redux";
import { CreateValidation } from "./validation";
import useWindowSize from "../../../../hooks/useWindowSize";
import { UpdateCoacheLinksAction } from "../../../../redux/coaches.reducer";

const init_create = {
  Instagram: "",
  Youtube: "",
  Facebook: "",
  Twitter: "",
  Linkedin: "",
  PortfolioUrl: "",
  SnapChat: "",
};

function UpdateLinks(props) {
  const { open, handleClose, title = "Update Popup", value } = props;
  const payload = useSelector((state) => state.CoacheReducer.payload);
  const [item, setItem] = useState({ ...init_create });
  const dispatch = useDispatch();
  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "500px";
      case "lg":
        return "500px";
      case "md":
        return "500px";
      case "sm":
        return "500px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  useEffect(() => {
    setItem(value);
  }, [value]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = () => {
    if (CreateValidation(item)) {
      dispatch(UpdateCoacheLinksAction(item, handleClose));
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize(), maxHeight: "90vh" }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-12 md:col-12">
          <label>Coache Facebook Link</label>
          <span className="p-input-icon-left">
            <i class="fa-brands fa-facebook-f"></i>
            <InputText
              name="Facebook"
              value={item.Facebook}
              placeholder="https://www.facebook.com/"
              onChange={handleChange}
              required
              autoFocus
            />
          </span>
        </div>

        <div className="field col-12 md:col-12">
          <label>Coache TikTok</label>
          <span className="p-input-icon-left">
            <i class="fa-brands fa-tiktok"></i>
            <InputText
              name="TikTok"
              value={item.TikTok}
              placeholder="https://www.tiktok.com/"
              onChange={handleChange}
              required
              autoFocus
            />
          </span>
        </div>

        <div className="field col-12 md:col-12">
          <label>Coache Youtube</label>
          <span className="p-input-icon-left">
            <i class="fa-brands fa-youtube"></i>
            <InputText
              name="Youtube"
              value={item.Youtube}
              placeholder="https://www.youtube.com/"
              onChange={handleChange}
              required
              autoFocus
            />
          </span>
        </div>

        <div className="field col-12 md:col-12">
          <label>Coache Instagram</label>
          <span className="p-input-icon-left">
            <i class="fa-brands fa-instagram"></i>
            <InputText
              name="Instagram"
              value={item.Instagram}
              placeholder="https://www.instagram.com/"
              onChange={handleChange}
              required
              autoFocus
            />
          </span>
        </div>

        <div className="field col-12 md:col-12">
          <label>Coache Twitter</label>
          <span className="p-input-icon-left">
            <i class="fa-brands fa-twitter"></i>
            <InputText
              name="Twitter"
              value={item.Twitter}
              placeholder="https://www.twitter.com/"
              onChange={handleChange}
              required
              autoFocus
            />
          </span>
        </div>

        <div className="field col-12 md:col-12">
          <label>Coache Linkedin</label>
          <span className="p-input-icon-left">
            <i class="fa-brands fa-linkedin-in"></i>
            <InputText
              name="Linkedin"
              value={item.Linkedin}
              placeholder="https://www.linkedin.com/"
              onChange={handleChange}
              required
              autoFocus
            />
          </span>
        </div>

        <div className="field col-12 md:col-12">
          <label>Coache Snapchat</label>
          <span className="p-input-icon-left">
            <i class="fa-brands fa-snapchat"></i>
            <InputText
              name="SnapChat"
              value={item.SnapChat}
              placeholder="https://www.snapchat.com/"
              onChange={handleChange}
              required
              autoFocus
            />
          </span>
        </div>

        <div className="field col-12 md:col-12">
          <label>Coache Portfolio Url</label>
          <span className="p-input-icon-left">
            <i class="fa-solid fa-link"></i>
            <InputText
              name="PortfolioUrl"
              value={item.PortfolioUrl}
              placeholder="https://www.portfolio.com/"
              onChange={handleChange}
              required
              autoFocus
            />
          </span>
        </div>
      </div>
    </Dialog>
  );
}

export default UpdateLinks;
