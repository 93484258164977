import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, HashRouter, Route, Redirect } from "react-router-dom";
import { GetUserByToken } from "../redux/user.reducer";

import Spinner from "../MyComponents/Spinner/Spinner";
// ** pages
import Login from "../pages/login/Login";
import DashboardPage from "../pages/dashboard/Dashboard";
// import { socket } from "../functions/socket.io";

function MainRoutest() {
  const user = useSelector((state) => state.UserReducer);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetUserByToken(succ, fail));
    // socket.on("connect", () => {
    //   socket.on("onlineuser", (data) => {
    //     console.log(data);
    //   });
    // });
  }, []);

  // useEffect(() => {
  //   if (user.is_connected) socket.emit("newUser", user.user.email);
  // }, [user]);

  const succ = (resp) => {
    setLoading(false);
  };
  const fail = (error) => {
    setLoading(false);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {!user.is_connected && <NotSignedRoutes />}
      {user.is_connected && <AdminRoutes />}
    </>
  );
}

const NotSignedRoutes = () => {
  return (
    <HashRouter>
      <Route path="/sign-in" component={Login} />
      <Route path="/forget-pass" component={Login} />
      <Route path="/dashboard">
        <Redirect to="/sign-in" />
      </Route>
      <Route path="/*">
        <Redirect to="/sign-in" />
      </Route>
    </HashRouter>
  );
};

const AdminRoutes = () => {
  return (
    <HashRouter>
      <Route path="/dashboard" component={DashboardPage} />
      <Route path="/*">
        {/* <Redirect to="/dashboard" /> */}
        <Redirect to="/dashboard" />
      </Route>
    </HashRouter>
  );
};

export default MainRoutest;
