import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetServicesAction } from "../../redux/services.reducer";

function useServices() {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.ServiceReducer.services);

  useEffect(() => {
    dispatch(GetServicesAction());
  }, []);

  return services;
}

export default useServices;
