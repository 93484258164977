import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

import { useSelector, useDispatch } from "react-redux";

import Pagination from "../../../MyComponents/Pagination/Pagination";

import DeleteDialog from "./popups/DeleteDialog";
import AddDialgog from "./popups/AddDialgog";
import UpdateDialog from "./popups/UpdateDialog";

import FilterComp from "./Filter";

import usePopup from "../../../hooks/usePopup";
import { GetSportsAction } from "../../../redux/sports.reducer";
import ImgBack from "../../../MyComponents/Image/ImgBack";

const init_filter = {
  // email:"",
  pays: "",
  suspended: "",
  verified: "",
};

const ActionBodyTemplate = ({ row }) => {
  const [openU, handleOpenU, handleCloseU] = usePopup(row);
  const [openD, handleOpenD, handleCloseD] = usePopup(row);

  return (
    <div
      className="actions"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {/* ################## UPDATE ################## */}
      <Button
        icon="pi pi-pencil"
        tooltip={`Update Sport ${row.title}`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        onClick={() => handleOpenU()}
      />

      {openU && (
        <UpdateDialog
          open={openU}
          handleClose={handleCloseU}
          value={row}
          title={`Update Sport ${row.title}`}
        />
      )}

      {/* ################## DELETE ################## */}

      <Button
        icon="pi pi-trash"
        tooltip={`Delete Sport ${row.title}`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-danger mr-0"
        onClick={() => handleOpenD()}
      />

      {openD && (
        <DeleteDialog
          open={openD}
          handleClose={handleCloseD}
          value={row}
          title={`Delete Sport ${row.title}`}
        />
      )}
    </div>
  );
};

const ManageSports = () => {
  const sports = useSelector((state) => state.SportReducer.sports);
  const dispatch = useDispatch();

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;
  const [openAdd, handleOpenAdd, handleCloseAdd] = usePopup("add");
  const [Filter, setFilter] = useState({ ...init_filter });
  //-------------------------------- Dialog States --------------------------------------------

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetSportsAction(Filter));
  };

  useEffect(() => {
    reloadData();
  }, [Filter]);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Sports</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            tooltip="Reload Data"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />
          <Button
            label="New"
            icon="pi pi-plus"
            tooltip="Add Sport"
            tooltipOptions={{ position: "bottom" }}
            className="p-button-primary mr-2"
            onClick={handleOpenAdd}
          />
          {openAdd && (
            <AddDialgog
              open={openAdd}
              handleClose={handleCloseAdd}
              title="Add New Sport"
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const SportName = (row) => {
    return `${row?.title}`;
  };

  const Description = (row) => {
    return row?.description;
  };

  const Actions = (row) => {
    return <ActionBodyTemplate row={row} />;
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

          <DataTable
            value={[...sports]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No Sport found."
            header={
              <FilterComp
                filter_name="Filter Sports"
                init_filter={init_filter}
                setFilter={setFilter}
              />
            }
            responsiveLayout="scroll"
          >
            <Column field="Sport Name" header="Sport Name" body={SportName} />
            <Column
              field="Sport Description"
              header="Sport Description"
              body={Description}
            />
            <Column header="Actions" body={Actions} />
          </DataTable>
          <Pagination max={max} onPageChange={setPage} />
        </div>
      </div>
    </div>
  );
};

export default ManageSports;
