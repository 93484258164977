import { toast } from "react-hot-toast";
import axios from "../custom/axios";

// ################################# KEYS #################################

const keys = {
  update_consultmodes: "update_consultmodes",
  payload: "payloade_consultmode",
  all: "all_consultmode",
};

// ################################# INIT #################################

export const InitialState = {
  payload: false,
  data: [],
};

// ################################# REDUCERS #################################

export const ConsultmodeReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.update_consultmodes:
      return { ...state, data: action.value };
    case keys.payload:
      return { ...state, payload: action.value };
    default:
      return state;
  }
};

// ################################# ACTIONS #################################

const AddConsultmodeAction = (consultmode, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.post(`/api/consult-mode/create`, {
        ...consultmode,
      });

      console.log(response?.data);

      dispatch(GetConsultmodesAction());
      toast.success("Consultmode added successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const GetConsultmodesAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.get(`/api/consult-mode/get-all`);

      console.log(response?.data);

      // every thing is ok here
      dispatch({
        type: keys.all,
        value: {
          payload: false,
          data: response?.data?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateConsultmodeAction = (consultmode, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(
        `/api/consult-mode/update/${consultmode._id}`,
        {
          ...consultmode,
        }
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetConsultmodesAction());
      toast.success("Consultmode updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const DeleteConsultmodeAction = (consultmode, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.delete(
        `/api/consult-mode/delete/${consultmode._id}`
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetConsultmodesAction());
      toast.success("Consultmode deleted successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  AddConsultmodeAction,
  GetConsultmodesAction,
  DeleteConsultmodeAction,
  UpdateConsultmodeAction,
};
