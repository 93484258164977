// https://fontawesome.com/search?o=r&m=free

export const menu = [
  {
    label: "Dashboard",
    items: [
      {
        label: "Dashboard",
        icon: "fa-solid fa-chart-line",
        to: "/dashboard/",
      },
      {
        label: "Manage Admins",
        icon: "fa-solid fa-user-tie",
        to: "/dashboard/admins",
      },
    ],
  },
  {
    label: "Categories",
    items: [
      {
        label: "Sports",
        icon: "fa-solid fa-dumbbell",
        to: "/dashboard/sports",
      },
      {
        label: "Coach Services",
        icon: "fa-solid fa-hand-holding-dollar",
        to: "/dashboard/services",
      },
      {
        label: "Nutri Services",
        icon: "fa-solid fa-hand-holding-dollar",
        to: "/dashboard/nut-services",
      },
      {
        label: "Nutri Approches",
        icon: "fa-solid fa-wheat-awn-circle-exclamation",
        to: "/dashboard/nut-approches",
      },
      {
        label: "Nutri Consult Mode",
        icon: "fa-solid fa-stethoscope",
        to: "/dashboard/nut-consult-mode",
      },
    ],
  },
  {
    label: "Manage People",
    items: [
      {
        label: "Users",
        icon: "fa-solid fa-users",
        to: "/dashboard/users",
      },
      {
        label: "Coaches",
        icon: "fa-solid fa-chalkboard-user",
        to: "/dashboard/coaches",
      },
      {
        label: "Nutritionists",
        icon: "fa-solid fa-user-doctor",
        to: "/dashboard/nutritionists",
      },
    ],
  },
  {
    label: "Website Content",
    items: [
      {
        label: "Manage Blogs",
        icon: "fa-solid fa-pen-nib",
        to: "/dashboard/blogs",
      },
      {
        label: "Conversations",
        icon: "pi pi-fw pi-comments",
        to: "/dashboard/conversation",
      },
      {
        label: "Mailing",
        icon: "pi pi-fw pi-envelope",
        to: "/dashboard/mailing",
      },
    ],
  },
  {
    label: "Transaction & Bills",
    items: [
      {
        label: "Transaction ",
        icon: "pi pi-fw pi-credit-card",
        to: "/dashboard/transaction",
      },
      {
        label: "Bills ",
        icon: "pi pi-fw pi-wallet",
        to: "/dashboard/bills",
      },
    ],
  },
];
