import React from "react";

function ImgBack({
  src = "",
  alt = "",
  className = "",
  style = {},
  onClick = () => {},
}) {
  // let backend_url = process.env.REACT_APP_API_HOST;
  let backend_url = "https://backend.trena.pro/api";
  return (
    <img
      className={className}
      style={style}
      onClick={onClick}
      src={backend_url + src}
      alt={alt}
    />
  );
}

export default ImgBack;
