import { toast } from "react-hot-toast";

export const CreateValidation = (item) => {
  if (item?.fullname?.length === 0) {
    toast.error("Full Name is required");
    return false;
  }

  if (item?.email?.length === 0) {
    toast.error("Email is required");
    return false;
  }

  if (item?.password?.length === 0) {
    toast.error("Password is required");
    return false;
  }

  if (item?.phoneNumber?.length === 0) {
    toast.error("Phone is required");
    return false;
  }

  return true;
};

export const UpdateValidation = (item) => {
  if (item?.fullname?.length === 0) {
    toast.error("Full Name is required");
    return false;
  }

  if (item?.email?.length === 0) {
    toast.error("Email is required");
    return false;
  }

  if (item?.phoneNumber?.length === 0) {
    toast.error("Phone is required");
    return false;
  }

  return true;
};
