import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useSelector } from "react-redux";
import useWindowSize from "../../../../hooks/useWindowSize";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import CustomAxios from "../../../../custom/axios";

function UpdateCertifs(props) {
  const { open, handleClose, title = "Update Popup", value } = props;
  const payload = useSelector((state) => state.CoacheReducer.payload);
  const [item, setItem] = useState([]);
  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "600px";
      case "lg":
        return "600px";
      case "md":
        return "600px";
      case "sm":
        return "600px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  useEffect(() => {
    CustomAxios.get(`/api/certifs/get-all/${value._id}`).then((res) => {
      console.log(res.data.data);
      setItem(res.data.data);
    });
  }, [value]);

  const handleChange = (event) => {
    const { value } = event.target;
    setItem(value);
  };

  const handleSubmit = async () => {
    try {
      const resp = await CustomAxios.get(`/api/certifs/get-all/${value._id}`);
      const initItems = resp.data.data;

      for (let i = 0; i < initItems.length; i++) {
        let currentItem = initItems[i];
        let found = false;
        for (let j = 0; j < item.length; j++) {
          if (currentItem._id === item[j]._id) {
            found = true;
          }
        }
        if (!found) {
          await CustomAxios.delete(`/api/certifs/delete/${currentItem._id}`);
        }
      }

      for (let i = 0; i < item.length; i++) {
        if (item[i]._id) {
          await CustomAxios.put(`/api/certifs/update/${item[i]._id}`, {
            ...item[i],
            coach: value._id,
          });
        } else {
          await CustomAxios.post(`/api/certifs/create`, {
            ...item[i],
            coach: value._id,
          });
        }
      }
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize(), maxHeight: "90vh" }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-12 md:col-12">
          <label>Coash Education & Certifs</label>
          <MultiCertifs
            name="certifs"
            value={item}
            onChange={handleChange}
            required
          />
        </div>
      </div>
    </Dialog>
  );
}

export const MultiCertifs = ({
  onChange = () => {},
  name: itemName = "",
  value = [],
}) => {
  const [certs, setCerts] = useState([]);

  useEffect(() => {
    setCerts(value);
  }, [value]);

  const AddCert = () => {
    setCerts([
      ...certs,
      {
        title: "",
        description: "",
        type: "Certification",
      },
    ]);
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const newCerts = [...certs];
    newCerts[index][name] = value;
    setCerts([...newCerts]);
    onChange({ target: { itemName, value: certs } });
  };

  const RemoveCert = (index) => {
    certs.splice(index, 1);
    setCerts([...certs]);
    onChange({ target: { itemName, value: certs } });
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {certs.map((tag, index) => {
        return (
          <span
            key={index}
            className="p-input-icon-right"
            style={{ width: "100%", margin: "5px 0px 5px 0px" }}
          >
            <InputText
              name="title"
              value={tag.title}
              placeholder={`Certif Title ${index + 1}`}
              onChange={(e) => handleChange(e, index)}
            />
            <Dropdown
              value={tag.type}
              className="mt-2"
              name="type"
              onChange={(e) => {
                handleChange(
                  { target: { name: "type", value: e.value } },
                  index
                );
              }}
              options={["Certification", "Diplome"]}
              placeholder="Select"
              style={{ width: "100%" }}
            />
            <InputTextarea
              key={index}
              name="description"
              className="mt-2"
              value={tag.description}
              rows={3}
              placeholder={`Certif Description ${index + 1}`}
              onChange={(e) => handleChange(e, index)}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                label="Remove"
                icon="pi pi-trash"
                className="p-button-filled p-button-danger"
                style={{
                  width: "25%",
                  margin: "5px 0px",
                }}
                onClick={() => {
                  RemoveCert(index);
                }}
              />
            </div>

            <span
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "black",
                margin: "15px 0px 5px 0px",
                display: "block",
              }}
            />
          </span>
        );
      })}
      <Button
        label="Add Certificaton / Diplome"
        icon="pi pi-plus"
        className="p-button-filled"
        style={{ width: "100%", margin: "5px 0.5% 5px 0.5%" }}
        onClick={AddCert}
      />
    </div>
  );
};

export default UpdateCertifs;
