import { toast } from "react-hot-toast";
import axios from "../custom/axios";

// ################################# KEYS #################################

const keys = {
  update_nutservices: "update_nutservices",
  payload: "payloade_nutservice",
  all: "all_nutservice",
};

// ################################# INIT #################################

export const InitialState = {
  payload: false,
  data: [],
};

// ################################# REDUCERS #################################

export const NutserviceReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.update_nutservices:
      return { ...state, data: action.value };
    case keys.payload:
      return { ...state, payload: action.value };
    default:
      return state;
  }
};

// ################################# ACTIONS #################################

const AddNutserviceAction = (nutservice, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.post(`/api/nutr-service/create`, {
        ...nutservice,
      });

      console.log(response?.data);

      dispatch(GetNutservicesAction());
      toast.success("Nutservice added successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const GetNutservicesAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.get(`/api/nutr-service/get-all`);

      console.log(response?.data);

      // every thing is ok here
      dispatch({
        type: keys.all,
        value: {
          payload: false,
          data: response?.data?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateNutserviceAction = (nutservice, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(
        `/api/nutr-service/update/${nutservice._id}`,
        {
          ...nutservice,
        }
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetNutservicesAction());
      toast.success("Nutservice updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const DeleteNutserviceAction = (nutservice, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.delete(
        `/api/nutr-service/delete/${nutservice._id}`
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetNutservicesAction());
      toast.success("Nutservice deleted successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  AddNutserviceAction,
  GetNutservicesAction,
  DeleteNutserviceAction,
  UpdateNutserviceAction,
};
