import { toast } from "react-hot-toast";
import axios from "../custom/axios";

// ################################# KEYS #################################

const keys = {
  update_users: "update_users",
  payload: "payloade_users",
  all: "all_users",
};

// ################################# INIT #################################

export const InitialState = {
  payload: false,
  users: [],
};

// ################################# REDUCERS #################################

export const UsersReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.update_users:
      return { ...state, user: action.value };
    case keys.payload:
      return { ...state, payload: action.value };
    default:
      return state;
  }
};

// ################################# ACTIONS #################################

const AddUsersAction = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.post(`/api/user/create`, {
        ...user,
      });

      console.log(response?.data);

      dispatch(GetUsersAction());
      toast.success("User added successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const GetUsersAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.get(`/api/user/get-all`);

      console.log(response?.data);

      // every thing is ok here
      dispatch({
        type: keys.all,
        value: {
          payload: false,
          users: response?.data?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateUsersAction = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(`/api/user/update/${user._id}`, {
        ...user,
      });

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetUsersAction());
      toast.success("User updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const DeleteUsersAction = (user, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.delete(`/api/user/delete/${user._id}`);

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetUsersAction());
      toast.success("User deleted successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

export { AddUsersAction, GetUsersAction, DeleteUsersAction, UpdateUsersAction };
