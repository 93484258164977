import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import axios from "../../custom/axios";
import toast from "react-hot-toast";
import ImgBack from "../Image/ImgBack";

const Uploader = (props) => {
  const { label, onChange, name, value, style = {} } = props;
  const [Image, setImage] = useState({ url: "", progress: 0, done: false });
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const changeImg = (event) => {
    let file = event.target.files[0];
    UploadImg(file, Image, setImage);
  };

  useEffect(() => {
    if (Image.done) {
      let event = { target: { name: name, value: Image.url } };
      onChange(event);
    }
  }, [Image]);

  const Label = () => {
    if (Image.done) {
      return "Image Uploaded";
    }

    if (Image.progress === 0) {
      return label;
    }

    return `Uploading ${Image.progress}%`;
  };

  return (
    <label className="w-full" style={style}>
      <Button
        className="m-auto block mb-2 w-full"
        label={Label()}
        icon={`pi ${Image.done ? "pi-check-square" : "pi-cloud-upload"}`}
        loading={Image.progress !== 100 && Image.progress !== 0}
        onClick={handleClick}
      />
      <input
        style={{ display: "none" }}
        ref={inputRef}
        onChange={changeImg}
        accept="image/*"
        multiple
        type="file"
      />
      {value && (
        <ImgBack
          style={{ width: "100px", display: "block", margin: "auto" }}
          src={value}
          alt="img"
        />
      )}
    </label>
  );
};

const UploadImg = async (file, Image, SetImage) => {
  // Initial File before upload
  const formData = new FormData();
  formData.append("image", file);

  // To get the progress
  const config = {
    onUploadProgress: (progressEvent) => {
      SetImage({
        ...Image,
        progress: Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        ),
      });
    },
  };
  // the axios upload
  return await axios
    .post("/api/upload/images/single", formData, config)
    .then((response) => {
      console.log(response);
      SetImage({
        ...Image,
        url: response.data.file,
        done: response.status === 200,
      });
    })
    .catch((error) => {
      toast.error("Error Uploading Image");
    });
};

export default Uploader;
