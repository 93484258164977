import { toast } from "react-hot-toast";
import axios from "../custom/axios";

// ################################# KEYS #################################

const keys = {
  update_nutritionists: "update_nutritionists",
  payload: "payloade_nutritionist",
  all: "all_nutritionist",
};

// ################################# INIT #################################

export const InitialState = {
  payload: false,
  data: [],
};

// ################################# REDUCERS #################################

export const NutriReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.update_nutritionists:
      return { ...state, data: action.value };
    case keys.payload:
      return { ...state, payload: action.value };
    default:
      return state;
  }
};

// ################################# ACTIONS #################################

const AddNutriAction = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.post(`/api/nutritionist/register`, {
        ...data,
      });

      console.log(response?.data);

      dispatch(GetNutriAction());
      toast.success("Nutritionist added successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response?.data);
      toast.error(error?.response?.data?.message);
    }
  };
};

const GetNutriAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.get(`/api/nutritionist/get-all`);

      console.log(response?.data);

      // every thing is ok here
      dispatch({
        type: keys.all,
        value: {
          payload: false,
          data: response?.data?.data,
        },
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateNutriAction = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(`/api/nutritionist/update/${data._id}`, {
        ...data,
      });

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetNutriAction());
      toast.success("Nutritionist updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateNutriLinksAction = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(
        `/api/nutritionist/update-social-links/${data._id}`,
        {
          ...data,
        }
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetNutriAction());
      toast.success("Nutritionist updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateNutriServicesAction = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(
        `/api/nutritionist/update-services-nut/${data._id}`,
        {
          ...data,
        }
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetNutriAction());
      toast.success("Nutritionist updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateNutriConsultModeAction = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(
        `/api/nutritionist/update-consult-mode/${data._id}`,
        {
          ...data,
        }
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetNutriAction());
      toast.success("Nutritionist updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateNutriAprocheAction = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(
        `/api/nutritionist/update-aproche-nut/${data._id}`,
        {
          ...data,
        }
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetNutriAction());
      toast.success("Nutritionist updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateNutriTagsAction = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(
        `/api/nutritionist/update-tags/${data._id}`,
        {
          ...data,
        }
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetNutriAction());
      toast.success("Nutritionist updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const UpdateNutriImagesAction = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.put(
        `/api/nutritionist/update-images/${data._id}`,
        {
          ...data,
        }
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetNutriAction());
      toast.success("Nutritionist updated successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

const DeleteNutriAction = (data, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      // to get response from back
      const response = await axios.delete(
        `/api/nutritionist/delete/${data._id}`
      );

      console.log(response?.data);

      // every thing is ok here
      dispatch(GetNutriAction());
      toast.success("Nutritionist deleted successfully");
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
      console.log(error?.response);
      toast.error(error?.response?.data?.message);
    }
  };
};

export {
  AddNutriAction,
  GetNutriAction,
  DeleteNutriAction,
  UpdateNutriAction,
  UpdateNutriTagsAction,
  UpdateNutriLinksAction,
  UpdateNutriImagesAction,
  UpdateNutriAprocheAction,
  UpdateNutriServicesAction,
  UpdateNutriConsultModeAction,
};
