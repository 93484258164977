import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetNutservicesAction } from "../../redux/NutrService.reducer";

function useAprocheNuts() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.NutserviceReducer.data);

  useEffect(() => {
    dispatch(GetNutservicesAction());
  }, []);

  return data;
}

export default useAprocheNuts;
