import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSportsAction } from "../../redux/sports.reducer";

function useSports() {
  const dispatch = useDispatch();
  const sports = useSelector((state) => state.SportReducer.sports);

  useEffect(() => {
    dispatch(GetSportsAction());
  }, []);

  return sports;
}

export default useSports;
