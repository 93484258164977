import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetConsultmodesAction } from "../../redux/ConsultMode.reducer";

function useConsultationMode() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ConsultmodeReducer.data);

  useEffect(() => {
    dispatch(GetConsultmodesAction());
  }, []);

  return data;
}

export default useConsultationMode;
