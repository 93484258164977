import React from "react";
import { Dialog } from "primereact/dialog";

import useWindowSize from "../../../../hooks/useWindowSize";
import { Divider } from "primereact/divider";
import ImgBack from "../../../../MyComponents/Image/ImgBack";

function ShowDialog(props) {
  const { open, handleClose, title = "Show Dialog", value } = props;

  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "600px";
      case "lg":
        return "600px";
      case "md":
        return "500px";
      case "sm":
        return "500px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize() }}
      header={title}
      modal
      className="p-fluid"
      onHide={handleClose}
    >
      <Divider layout="horizontal" style={{ transform: "translateY(-10px)" }} />
      <div>
        <h4>{value.title}</h4>
        {value.content.map((item, index) => {
          return (
            <div key={index}>
              {item.type === "Title" && <h5 className="my-3">{item.value}</h5>}
              {item.type === "Paragraph" && (
                <p className="my-3">{item.value}</p>
              )}
              {item.type === "Image" && (
                <ImgBack
                  src={item.value}
                  alt={item.value}
                  style={{ width: "100%" }}
                />
              )}
            </div>
          );
        })}
      </div>
    </Dialog>
  );
}

export default ShowDialog;
