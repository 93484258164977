import { toast } from "react-hot-toast";

export const CreateValidation = (item) => {
  if (item?.fullname?.length === 0) {
    toast.error("Fullname is required");
    return false;
  }

  if (item?.email?.length === 0) {
    toast.error("Email required");
    return false;
  }

  if (item?.phoneNumber?.length === 0) {
    toast.error("PhoneNumber required");
    return false;
  }

  if (item?.speciality.length === 0) {
    toast.error("Speciality required");
    return false;
  }

  if (item?.birthDate?.length === 0) {
    toast.error("Birth Date required");
    return false;
  }

  if (item?.Location?.length === 0) {
    toast.error("Location required");
    return false;
  }

  if (item?.sex?.length === 0) {
    toast.error("Gender required");
    return false;
  }

  if (item?.DateStartExp?.length === 0) {
    toast.error("Date Start Exp required");
    return false;
  }

  if (item?.About?.length === 0) {
    toast.error("About required");
    return false;
  }

  if (item?.MainImg?.length === 0) {
    toast.error("Main Img required");
    return false;
  }

  if (item?.CoverProfileImage?.length === 0) {
    toast.error("Cover Profile Image required");
    return false;
  }

  return true;
};
